<template>
  <b-row class="filters-container">
    <b-col cols="3">
      <b-form-group>
        <label for="status">Grupo de profissionais</label>
        <Autocomplete
          id="professional_group"
          v-model="filters.professional_group"
          :options="professionalGroups"
          placeholder="Selecionar"
          class="w-100"
        />
      </b-form-group>
    </b-col>

    <b-col cols="3">
      <b-form-group>
        <label for="beneficiaries">Favorecidos</label>       
        <BeneficiarySelect
          :value="filters.beneficiary_id"
          v-model="filters.beneficiary_id"
        />
        <Close
          v-if="filters.beneficiary_id"
          class="close-item-icon mt-1"
          @click="() => filters.beneficiary_id = null"
        />
      </b-form-group>
    </b-col>

    <b-col cols="3">
      <b-form-group>
        <label for="status">Grupo de itens</label>
        <Autocomplete
          id="item_group"
          v-model="filters.item_group"
          :options="procedureGroups"
          placeholder="Selecionar"
          class="w-100"
        />
      </b-form-group>
    </b-col>

    <b-col cols="3">
      <b-form-group>
        <label for="procedure_id">Item</label>
        <ItemSelect 
            placeholder="Buscar item..."
            :hasCreateItem="false"
            @select="item => filters.item_id = item?.id"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { showFeature } from '@/utils/permissionsHelp'
export default {
  name: 'Rules',
  components: {
    Autocomplete: () => import('@/components/Autocomplete'),
    BeneficiarySelect: () => import('@/components/General/BeneficiarySelect'),
    Close: () => import('@/assets/icons/close.svg'),
    ItemSelect: () => import('@items/components/ItemSelect'),
  },
  mounted(){
    if(showFeature('Centro cirúrgico')){
      this.procedureGroups.push({
        label: 'Centro Cirúrgico',
        value: 'SURGICAL'
      })
    }
  },
  data() {
    return {
      filters: {
        professional_group: null,
        beneficiary_id: null,
        item_group: null,
        item_id: null,
      },

      professionalGroups: [
        {
          label: 'Enfermeiro',
          value: 'NURSE'
        },
        {
          label: 'Gestor',
          value: 'MANAGER'
        },
        {
          label: 'Médico',
          value: 'DOCTOR'
        },
        {
          label: 'Medico-gestor',
          value: 'DOCTOR_MANAGER'
        },
        {
          label: 'Anestesista',
          value: 'DOCTOR_ANESTHETIST'
        },
        {
          label: 'Secretária',
          value: 'SECRETARY'
        },
        {
          label: 'Técnico',
          value: 'TECHNICIAN'
        },
        {
          label: 'Tecnólogo',
          value: 'TECHNOLOGIST'
        },
        {
          label: 'Externo',
          value: 'EXTERNAL'
        }
      ],
      procedureGroups: [
        {
          label: 'Consulta',
          value: 'APPOINTMENT'
        },
        {
          label: 'Exame',
          value: 'EXAM'
        },
        {
          label: 'Procedimento',
          value: 'PROCEDURE'
        },
        {
          label: 'Telemedicina',
          value: 'TELEMEDICINE'
        },
        {
          label: 'Retorno',
          value: 'RETURN'
        },
        {
          label: 'Produtos',
          value: 'Product'
        }
      ]
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('changeFilters', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-container {
  .search-container {
    display: flex;
    flex-direction: row;
    position: relative;

    .icon {
      position: absolute;
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      height: 100%;
      margin-left: 10px;
    }

    input {
      padding-left: 40px !important;
    }
  }

  .close-item-icon{
    width: 25px;
    height: 25px;
    fill: rgb(127, 145, 210);
    position: absolute;
    right: 15px;
    top: 38%;
  }
}
</style>
